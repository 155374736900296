import { useEffect, useRef, useState } from 'react';
// @mui
import { Card, Grid, Stack, Button, Container, TextField, MenuItem, Select, Typography, Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

// components
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { DeleteOutline, StackedBarChartSharp } from '@mui/icons-material';
// import AddPolicy from './Add';
import EditPolicy from './Edit'
import DeleteModel from '../../components/Deletemodle';
import { apiget, deleteManyApi, apiSearch, apipost } from '../../service/api';
import TableStyle from '../../components/TableStyle';

// import { MdOutlineWidthFull } from 'react-icons/md';

const userid = localStorage.getItem('user_id');
const userRole = localStorage.getItem('userRole');

function CustomToolbar({ selectedRowIds, searchText, setSearchText, fetchdata }) {
  const [opendelete, setOpendelete] = useState(false);
  const inputRef = useRef(null); // Create a ref for the input

  // Handle search on input change
  const onEnter = (e) => {
    const value = e.target.value;
    setSearchText(value); // Keep the input value updated in the parent component
    fetchdata(value); // Call the API to fetch the filtered data
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Keep the input focused after state updates
    }
  }, [searchText]); // Run this effect whenever searchText changes

  const statuses = [
    { label: 'Due', color: 'primary.main' },
    { label: 'Active', color: 'success.main' },
    { label: 'Not Renewed', color: 'warning.main' },
    { label: 'Expired', color: 'error.main' },
  ];

  const handleCloseDelete = () => {
    setOpendelete(false);
  };

  const handleOpenDelete = () => {
    setOpendelete(true);
  };

  const deleteManyContact = async (data) => {
    await deleteManyApi('policy/deletemany', data);
    fetchdata();
    handleCloseDelete();
  };

  return (
    <GridToolbarContainer>
      <GridToolbar />
      {selectedRowIds.length > 0 && userRole === 'admin' && (
        <Button variant="text" sx={{ textTransform: 'capitalize' }} startIcon={<DeleteOutline />} onClick={handleOpenDelete}>
          Delete
        </Button>
      )}

      <TextField
        inputRef={inputRef} // Assign the ref to the TextField
        value={searchText} // Controlled input using the state from the parent
        onChange={onEnter} // Handle search as user types
        placeholder="Search..."
        variant="outlined"
        size="small"
        style={{ marginBottom: '10px' }}
      />


      <Stack direction={'row'} marginLeft={'auto'} alignItems={'center'} spacing={2}>
        {statuses.map((status) => (
          <Stack key={status.label} direction="row" spacing={1}  alignItems="center">
            <Box sx={{ width: 12, height: 12, borderRadius: '50%', bgcolor: status.color }} />
            <Typography fontSize={'14px'}>{status.label}</Typography>
          </Stack>
        ))}
      </Stack>

      <DeleteModel opendelete={opendelete} handleClosedelete={handleCloseDelete} deletedata={deleteManyContact} id={selectedRowIds} />
    </GridToolbarContainer>
  );
}

export const SearchPolicy = async (key) => {
  // const result = await apiSearch(`policy/search/:${key}`);
  // Handle the search result if needed
};

const Policy = () => {
  const [policyList, setPolicyList] = useState([]);
  const [sortedPolicyList, setSortedPolicyList] = useState([]);
  const [renewPolicyId, setRenewPolicyId] = useState(null);
  const [userAction, setUserAction] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [searchText, setSearchText] = useState(''); // State to keep track of search input

  const navigate = useNavigate();


  const userDataString = localStorage.getItem('user');
  const userData = JSON.parse(userDataString);
  const email = userData.emailAddress || '';


  // const handleOpenAdd = () => setOpenAdd(true);
  // const handleCloseAdd = () => setOpenAdd(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel);
  };

  const updatePolicyStatus = async (statusId, value) => {
    const result = await apipost(`policy/update-status/${statusId}`, { status: value });
    if (result && result.status === 200) {
      setUserAction(result)
    }
  }


  const columns = [
    {
      field: 's',
      headerName: 'M.',
      flex: 1,
      minWidth: 35,
      renderCell: (params) => {
        let circleColor;

        // Define the circle color based on the status
        switch (params.row.status) {
          case 'due':
            circleColor = 'primary.main'; // primary color for due
            break;
          case 'active':
            circleColor = 'success.main'; // success color for active
            break;
          case 'not renewed':
            circleColor = 'warning.main'; // warning color for not renewed
            break;
          case 'expired':
            circleColor = 'error.main'; // error color for expired
            break;
          default:
            circleColor = 'grey.500'; // default color if status doesn't match
            break;
        }

        return (
          <Box
            sx={{
              height: '12px',
              width: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              backgroundColor: circleColor, // dynamically set background color
            }}
          />
        );
      }
    }
    ,
    {
      field: 'insuredPersonName',
      headerName: 'Proposer Name',
      flex: 1,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      minWidth: 200,
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`);
        };
        return (
          <Grid key={`insured-${params.row._id}`} onClick={handleFirstNameClick}>
            {params?.row?.policyHolder ?
              params?.row?.policyHolder :
              params?.row?.insuredPersonName || '--'
            }
          </Grid>
        );
      }
    },
    userRole === 'admin' && {
      field: '',
      headerName: 'Insured Person Name',
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`);
        };
        return (
          <Grid key={`insuredPerson-${params.row._id}`} onClick={handleFirstNameClick}>
            {params?.row?.additionalInsureds[0]?.additionalInsuredPersonName
              ? params?.row?.additionalInsureds[0]?.additionalInsuredPersonName
              : params?.row?.vehicleNumber?.toUpperCase()}
          </Grid>
        );
      }
    },
    userRole === 'user' && {
      field: '',
      headerName: 'Insured Person Name',
      flex: 1,
      minWidth: 200,
      cellClassName: "name-column--cell name-column--cell--capitalize",
      renderCell: (params) => {
        const handleFirstNameClick = () => {
          navigate(`/dashboard/policy/view/${params.id}`);
        };
        return (
          <Grid key={`insuredPerson-${params.row._id}`} onClick={handleFirstNameClick}>
            {params?.row?.additionalInsureds[0]?.additionalInsuredPersonName
              ? params?.row?.additionalInsureds[0]?.additionalInsuredPersonName
              : params.row.policyHolder || '--'}
          </Grid>
        );
      }
    },
    {
      field: 'policyStartDate',
      headerName: 'Policy start date',
      flex: 1,
      minWidth: 130,
      valueFormatter: (params) => {
        const value = params.value;
        const date = value ? new Date(value) : null;
        if (date && !Number.isNaN(date.getTime())) {
          return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
        }
        return '--';
      },
    },
    {
      field: 'policyEndDate',
      headerName: 'Policy end date',
      flex: 1,
      minWidth: 130,
      valueFormatter: (params) => {
        const value = params.value;
        const date = value ? new Date(value) : null;
        if (date && !Number.isNaN(date.getTime())) {
          return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
        }
        return '--';
      },
    },
    userRole === 'admin' && {
      field: 'documentCount:',
      headerName: 'Doc.',
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return (
          <Box>{params.row.documentCount}</Box>
        )
      }
    },
    userRole === 'user' && {
      field: 'vehicleNumber',
      headerName: 'Vehicle Number',
      flex: 1,
      headerAlign: 'center',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%', textTransform: 'uppercase' }}
            className="capitalize-text"
          >
            {params?.row?.vehicleNumber ? params.row.vehicleNumber : '--'}
          </Grid>
        );
      }
    },
    {
      field: 'vehicleModel',
      headerName: 'Vehicle Model',
      flex: 1,
      headerAlign: 'center',
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
            className="capitalize-text"
          >
            {params?.row?.vehicleModel ? params.row.vehicleModel : '--'}
          </Grid>
        );
      }
    },
    {
      field: 'policyType',
      headerName: 'Policy Type',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'policyCompanyName',
      headerName: 'Policy Company',
      flex: 1,
      minWidth: 200,
    },
    userRole === 'admin' && {
      field: 'policySource',
      headerName: 'Source',
      flex: 1,
      headerAlign: 'center',
      minWidth: 400,
      renderCell: (params) => {
        return (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            {params?.row?.policySource}
          </Grid>
        );
      }
    },
    // userRole === 'admin' && {
    //   field: 'policyStartDate',
    //   headerName: 'Policy start date',
    //   flex: 1,
    //   minWidth: 200,
    //   valueFormatter: (params) => {
    //     const value = params.value;
    //     const date = value ? new Date(value) : null;
    //     if (date && !Number.isNaN(date.getTime())) {
    //         return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
    //     }
    //     return '--';
    // },
    // },
    // userRole === 'admin' && {
    //   field: 'policyEndDate',
    //   headerName: 'Policy end date',
    //   flex: 1,
    //   minWidth: 200,
    //   valueFormatter: (params) => {
    //     const value = params.value;
    //     const date = value ? new Date(value) : null;
    //     if (date && !Number.isNaN(date.getTime())) {
    //         return new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');
    //     }
    //     return '--';
    // },
    // },
    userRole === 'admin' && {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 220,
      renderCell: (params) => {
        const handleChange = (event) => {
          updatePolicyStatus(params.row._id, event.target.value)
        };

        return (
          <Select
            value={params.value}
            onChange={handleChange}
            fullWidth
          >

            <MenuItem value="active" disabled>Active</MenuItem>
            <MenuItem value="not renewed" disabled>Not Renewed</MenuItem>
            <MenuItem value="due" disabled>Due</MenuItem>
            <MenuItem value="expired">Expired</MenuItem>
          </Select>
        );
      },
    },
    userRole === 'admin' && {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        // const handleFirstNameClick = () => {
        //   navigate(`/dashboard/policy/view/${params.id}`);
        // };

        return (
          <Stack direction={"row"}>
            {/* <Button
              key={`button-${params.row._id}`}
              variant='text'
              size='small'
              color='primary'
              onClick={handleFirstNameClick}
            >
              <VisibilityIcon />
            </Button> */}
            <Button

              disabled={params.row.status !== 'expired'}

              sx={{ cursor: params.row.status === 'expired' ? 'pointer' : 'not-allowed' }}
              key={`button-${params.row._id}`}
              variant='text'
              size='small'
              color='primary'
              onClick={() => {
                setRenewPolicyId(params.id);
                handleOpenEdit();
              }}
            >
              Renew
            </Button>
          </Stack>
        );
      },
    },
  ].filter(Boolean); // Remove falsy values from the array






  const fetchdata = async (searchText = '') => {
    setLoading(true);

    if (userRole === "admin") {
      try {
        const result = await apiget(`policy/list?search=${searchText}`);

        if (result && result.status === 200) {
          const data = result?.data?.result;

          setPolicyList(Array.isArray(data) ? data : [data]);
        }
      } catch (error) {
        console.error("Failed to fetch policy data:", error);
      }
    }
    else {

      (async () => {

        try {
          // Fetch contact data
          const contactData = await apiget(`contact/user-contact?email=${email}`);

          let searchID = null;

          if (contactData?.data?.result.length !== 0) {
            searchID = contactData?.data?.result[0]._id;

          }

          if (searchID !== null) {

            try {

              const fetchPolicyByContactId = await apiget(`policy/user-policy-contact/${searchID}`);
              setPolicyList(fetchPolicyByContactId?.data)

            } catch (error) {
              console.log("Error fetching user family data", error);
            }
          }
          else {

            try {
              const fetchFamilyRoot = await apiget(`contact-relation/user-email?email=${email}`);

              const familyContactId = fetchFamilyRoot?.data?.result?.contact_id;

              const fetchPolicyByContactId = await apiget(`policy/user-policy-contact/${familyContactId}`);
              setPolicyList(fetchPolicyByContactId?.data)

            } catch (error) {
              console.log("Error fetching family root or user family data", error);
            }
          }


        } catch (error) {
          console.log("Error fetching contact data", error);
        }

      })();


    }
    setLoading(false);
  };

  useEffect(() => {
    // Create a function to determine which name to use for sorting
    const getNameToSortBy = (policy) => policy.policyHolder || policy.insuredPersonName || '';

    // Sort the policyList based on the selected name
    const sortedData = policyList.sort((a, b) => {
      const nameA = getNameToSortBy(a) || '';
      const nameB = getNameToSortBy(b) || '';

      // Use localeCompare to compare strings correctly
      return nameA.localeCompare(nameB);
    });

    // Update the sorted policy list
    setSortedPolicyList([...sortedData]);
  }, [policyList]);



  useEffect(() => {
    fetchdata();

  }, [userAction]);

  useEffect(() => {
    fetchdata();

  }, []);

  return (
    <>

      {/* <AddPolicy open={openAdd} handleClose={handleCloseAdd} setUserAction={setUserAction} /> */}
      <EditPolicy renew={'renew'} open={openEdit} handleClose={handleCloseEdit} setUserAction={setUserAction} id={renewPolicyId} fetchPolicy={fetchdata} />

      <Container>
        <Stack direction="column" alignItems="center" mb={5}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" mb={2}>
            <Typography variant="h4">Policy</Typography>
            {/* {userRole === 'admin' && (
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleOpenAdd}>
                New Policy
              </Button>
            )} */}
          </Stack>
        </Stack>

        <TableStyle>
          <Box width="100%">
            <Card style={{ height: '600px', paddingTop: '15px' }}>
              <DataGrid
                rows={sortedPolicyList}
                columns={columns}
                components={{ Toolbar: () => <CustomToolbar selectedRowIds={selectedRowIds} searchText={searchText} setSearchText={setSearchText} fetchdata={fetchdata} /> }}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectedRowIds}
                getRowId={(row) => row._id}
                loading={loading}
              // initialState={{
              //   pinnedColumns: {
              //     right: ['action'], // Pin the 'action' column to the left
              //   },
              // }}
              />
            </Card>
          </Box>
        </TableStyle>



      </Container>
    </>
  );
};

export default Policy;
