/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Select, FormControl, MenuItem, TextField, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// components
import { useEffect, useState } from 'react';
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { apiget } from '../service/api';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const [totalLead, setTotalLead] = useState({
    high: 0,
    mid: 0,
    low: 0
  });

  const [totalContact, setTotalContact] = useState(0);
  const [totalPolicy, setTotalPolicy] = useState(0);
  const [totalEvent, setTotalEvent] = useState(0);
  const [duePolicy, setDuePolicy] = useState(0);
  const [activePolicy, setActivePolicy] = useState(0);
  const [renewedPolicy, setRenewedPolicy] = useState(0);
  const [expiryCount, setExpiryCount] = useState(0);

  const [totalPremiumDue, setTotalPremiumDue] = useState(0);
  const [totalPremiumNotRenewed, setTotalPremiumNotRenewed] = useState(0);
  const [totalPremiumExpired, setTotalPremiumExpired] = useState(0);
  const [totalPremiumActive, setTotalPremiumActive] = useState(0);

  const userid = localStorage.getItem('user_id');
  const userRole = localStorage.getItem("userRole");

  const [selectedOption, setSelectedOption] = useState(''); // Default to no option
  const [startDate, setStartDate] = useState(null); // For start date in custom range
  const [currentDate] = useState(new Date()); // Automatically set the end date to current date


  // Handle dropdown selection change
  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

  };

  // Function to calculate and fetch data based on selection
  const fetchData = () => {
    let fromDate;

    // End date will always be the current date
    const toDate = currentDate.toISOString().split('T')[0];

    if (selectedOption) {
      const current = new Date();
      switch (selectedOption) {
        case 'Today':
          fromDate = toDate; // Today
          break;
        case 'Yesterday':
          fromDate = new Date(current.setDate(current.getDate() - 1)).toISOString().split('T')[0];
          break;
        case '1 Week':
          fromDate = new Date(current.setDate(current.getDate() - 7)).toISOString().split('T')[0];
          break;
        case '1 Month':
          fromDate = new Date(current.setMonth(current.getMonth() - 1)).toISOString().split('T')[0];
          break;
        case '6 Months':
          fromDate = new Date(current.setMonth(current.getMonth() - 6)).toISOString().split('T')[0];
          break;
        case 'custom':
          if (startDate) {
            fromDate = startDate.toISOString().split('T')[0]; // Custom date from calendar
          }
          break;
        default:
          fromDate = null;
      }
    }

    if (fromDate && toDate) {

      fetchLead(fromDate, toDate);
      getChartLabels(fromDate,toDate)
    }
  };


  useEffect(() => {
    fetchData();
  }, [selectedOption, startDate]);


  // lead api
  const fetchLead = async (startDate = '', endDate = '') => {
    let query = userRole === 'admin' ? `lead/list` : `lead/list/?createdBy=${userid}`;

    // Add startDate and endDate to the query if provided
    if (startDate || endDate) {
      query += `${userRole !== 'admin' ? '&' : '?'}startDate=${startDate}&endDate=${endDate}`;
    }

    const result = await apiget(query);

    if (result && result.status === 200) {
      setTotalLead({
        high: result?.data?.leadCountsData?.high || 0,
        mid: result?.data?.leadCountsData?.mid || 0,
        low: result?.data?.leadCountsData?.low || 0,
      });
    }
  };


  // contact api
  const fetchContact = async () => {
    const result = await apiget(userRole === 'admin' ? `contact/list` : `contact/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      setTotalContact(result?.data?.totalRecords)

    }
  }

  // contact api
  const fetchPolicy = async () => {
    const result = await apiget(userRole === 'admin' ? `policy/list` : `policy/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      setTotalPolicy(result?.data?.total_recodes);
      setExpiryCount(result?.data?.bothCount?.expiryCount);
      setActivePolicy(result?.data?.bothCount?.activeCount);
      setRenewedPolicy(result?.data?.bothCount?.notRenewedCount)
      setDuePolicy(result?.data?.bothCount?.dueCount)

      // amount total
      setTotalPremiumDue(result?.data?.bothCount?.totalPremiumDue)
      setTotalPremiumNotRenewed(result?.data?.bothCount?.totalPremiumNotRenewed)
      setTotalPremiumExpired(result?.data?.bothCount?.totalPremiumExpired)
      setTotalPremiumActive(result?.data?.bothCount?.totalPremiumActive)
    }
  }

  // contact api
  const fetchEvent = async () => {
    const result = await apiget(userRole === 'admin' ? `task/list` : `task/list/?createdBy=${userid}`)
    if (result && result.status === 200) {
      setTotalEvent(result?.data?.total_recodes)
    }
  }


  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JS
    const day = '01'; // Since you want the day to be fixed
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // Function to generate an array of month labels
  const generateMonthLabels = (start, end) => {
    const labels = [];
    const current = new Date(start);

    // Loop through each month until we reach the end date
    while (current <= end) {
      labels.push(formatDate(current));
      current.setMonth(current.getMonth() + 1); // Move to the next month
    }


    return labels;
  };

  // Get the start and end date for the chart
  const getChartLabels = (startDate='',currentDate='') => {
    
    const defaultStart = new Date(new Date().setFullYear(new Date().getFullYear() - 1)); // 1 year ago
    const actualStartDate = startDate ? new Date(startDate) : defaultStart;
    const actualEndDate = currentDate ? new Date(currentDate) : new Date();
    return generateMonthLabels(actualStartDate, actualEndDate);
  };


  useEffect(() => {
    fetchLead();
    fetchContact();
    fetchPolicy();
    fetchEvent();
   
  }, [])
  return (
    <>
      <Helmet>
        {/* <title> Dashboard | Minimal UI </title> */}
      </Helmet>

      <Container maxWidth="xl">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 5 }}>
            <Typography variant="h4" >
              Hello  Vikas ji
            </Typography>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <FormControl fullWidth sx={{ maxWidth: 200 }}>
                <Stack direction={'row'} alignItems={'center'} >
                  <Select

                    size='small'
                    fullWidth
                    value={selectedOption}
                    onChange={handleOptionChange}
                    displayEmpty
                  >
                    <MenuItem value="">Select Option</MenuItem>
                    <MenuItem value="Today" >Today</MenuItem>
                    <MenuItem value="Yesterday">Yesterday</MenuItem>
                    <MenuItem value="1 Week">Last 1 Week</MenuItem>
                    <MenuItem value="1 Month">Last 1 Month</MenuItem>
                    <MenuItem value="6 Months">Last 6 Months</MenuItem>
                    <MenuItem value="custom">Select from Calendar</MenuItem>
                  </Select>
                </Stack>

              </FormControl>

              {selectedOption === "custom" && (
                <Stack direction={'row'} alignItems={'center'}>
                  <TextField
                    type="date" // Specify the input type as date
                    size="small" // Set size to small
                    sx={{ width: '150px' }} // Adjust width as needed
                    InputLabelProps={{
                      shrink: true, // Ensures the label is shrunk for date input
                    }}
                    value={startDate ? startDate.toISOString().split("T")[0] : ''} // Format the date for input
                    onChange={(e) => {
                      setStartDate(new Date(e.target.value)); // Update state with the selected date
                    }}
                  />
                </Stack>
              )}
            </Stack>

          </Stack>
        </LocalizationProvider>

        <Grid container spacing={3}>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Due"
              total={duePolicy}
              icon={'ic:baseline-access-time'}
              amount={totalPremiumDue}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Active"
              total={activePolicy}
              color="info"
              icon={'ic:baseline-check-circle'}
              amount={totalPremiumActive}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Not Renewed"
              total={renewedPolicy}
              color="warning"
              icon={'ic:baseline-refresh'}
              amount={totalPremiumNotRenewed}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Expired"
              total={expiryCount}
              color="error"
              icon={'ic:baseline-warning'}
              amount={totalPremiumExpired}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Leads"
              chartData={[
                { label: 'High', value: totalLead.high },
                { label: 'Medium', value: totalLead.mid },
                { label: 'Low', value: totalLead.low },
              ]}
              chartColors={[
                theme.palette.error.main,
                theme.palette.warning.main,
                theme.palette.success.main,

              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              // chartLabels={[
              //   '01/01/2003',
              //   '02/01/2003',
              //   '03/01/2003',
              //   '04/01/2003',
              //   '05/01/2003',
              //   '06/01/2003',
              //   '07/01/2003',
              //   '08/01/2003',
              //   '09/01/2003',
              //   '10/01/2003',
              //   '11/01/2003',
              //   '12/01/2003'
              // ]}
              chartLabels={getChartLabels()}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },

              ]}
            />
          </Grid>



          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid>
        </Grid>
      </Container >
    </>
  );
}
